<template>
  <div class="p-5 w-70 flex justify-content-between flex-md-row flex-column">
    <div>
      <h1>Vite Account Deletion and Data Privacy</h1>
      <div class="m-3">
        <span>
          <b>How to Delete Your Vite Account:</b>
        </span>
        <p>
          We at Vite respect your privacy and your right to control your data. If you wish to delete your Vite account
          and
          associated data, you can do so by following these steps:
        </p>
        <ul class="mx-md-5 mx-0">
          <li>
            fill the form on the right and submit
          </li>
          <li>
            Sign in to your Vite account.
          </li>
          <li>
            Logout your account and await for the completion of the deletion process.
          </li>
        </ul>

        <span>
          <b>Data Deletion and Retention:</b>
        </span>
        <p>
          Once you initiate account deletion, Vite will begin the process of erasing your data. This includes
          information
          such as:
        </p>
        <ul class="mx-md-5 mx-0">
          <li>
            Your account profile information (name, email, username)
          </li>
          <li>
            Your activity history (within the Vite platform, if applicable)
          </li>
          <li>
            Any preferences or settings associated with your account
          </li>
        </ul>
      </div>
      <p class="p-3">
        <b>Please note: </b> There may be a brief retention period (up to14 days) before your data is completely erased
        from our
        systems. This allows us to handle any potential issues or requests related to your account deletion. After this
        period, your data will be permanently deleted and unrecoverable.
      </p>
      <p class="p-3">
        We encourage you to review our Privacy Policy for more details on how we handle your data:
        <a href="https://book.viteapp.co/privacy-policy">https://book.viteapp.co/privacy-policy</a>
      </p>
      <p class="p-3">
        If you have any questions about deleting your Vite account or your data privacy rights, please contact Vite
        Support.
      </p>
    </div>
    <iframe
      src="https://docs.google.com/forms/d/e/1FAIpQLSeQA9eB2BXfj-dF3hSBilG2REBhc5GZOL0M_xxKkSydJGuIeA/viewform?embedded=true"
      class="element" frameborder="0" marginheight="0" marginwidth="0">Loading…</iframe>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
h1 {
  font-size: 52px;
  color: #002b54;
}

h2 {
  font-size: 30px;
  color: #73c167;
}

/* Default width */
.element {
  width: 100%;
  height: 750px;
}

/* Width for large screens */
@media (min-width: 992px) {
  .element {
    width: 1040px;
  }
}
</style>
